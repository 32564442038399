body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.light .logoDark {
  display: none;
}

body.light .logoLight {
  display: initial;
}

body.dark .logoLight {
  display: none;
}

body.dark .logoDark {
  display: initial;
}

.MuiStepLabel-label.Mui-completed {
  color: unset;
}

body.light .MuiStepIcon-root.Mui-completed {
  color: rgba(0, 0, 0, 0.38);
}

body.dark .MuiStepIcon-root.Mui-completed {
  color: rgba(255, 255, 255, 0.5);
}

.stepActive .MuiStepIcon-root,
body.dark .MuiFormLabel-root.Mui-focused .MuiFormLabel-asterisk.Mui-error,
body.dark .MuiFormLabel-root.Mui-focused:not(.Mui-disabled),
body.dark .MuiCircularProgress-colorPrimary,
body.dark .MuiButton-textPrimary {
  color: #0288d1;
}

body.dark .MuiButton-outlinedPrimary {
  color: #0288d1;
  border-color: #0288d1;
}

body.dark
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline:not(.Mui-disabled) {
  border-color: #0288d1;
}

body.dark .MuiFilledInput-underline:after,
body.dark .MuiInput-underline:after {
  border-bottom-color: #0288d1;
}

body.dark .MuiButton-containedPrimary:not(.Mui-disabled) {
  background-color: #0288d1;
}

#root {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* .MuiPopover-paper {
  max-height: 180px !important;
} */
.MuiAccordion-root:before {
  opacity: 0 !important;
}

[data-tooltip] {
  position: relative;
  cursor: default;
}

[data-tooltip]::after {
  position: absolute;
  width: calc(100% + 16px);
  left: 0;
  bottom: 180%;
  text-align: center;
  box-sizing: border-box;
  content: attr(data-tooltip);
  padding: 8px;
  border-radius: 4px;
  font-size: 0.9em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #dadde0;
  background-color: #f7f8fa;
  z-index: 999;

  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.2s;
}

[data-tooltip]:hover::after {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.pf-c-login {
  display: flex;
  flex-direction: column;
}

.ReactCodeInput input[type="number"] {
  -webkit-text-security: disc;
  -moz-text-security: circle;
  --text-security: circle;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img.logoDark:not(.logoDarkLight),
img.logoLight:not(.logoDarkLight) {
  width: auto !important;
  height: 96px !important;
  max-width: 600px !important;
}

@media only screen and (max-width: 600px) {
  img.logoDark:not(.logoDarkLight),
  img.logoLight:not(.logoDarkLight) {
    width: auto !important;
    height: 96px !important;
    max-width: calc(100vw - 64px) !important;
  }
}

.pf-c-login__main,
.pf-c-login__header {
  display: flex !important;
  background: transparent !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.pf-c-login__header img {
  max-width: calc(100% - 32px) !important;
  max-height: 20vw;
}

.MuiOutlinedInput-adornedEnd {
  padding-right: 0;
}

a {
  cursor: pointer;
}

li.MuiListItem-root.MuiMenuItem-root {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

form {
  width: 100%;
}

.MuiInputBase-input {
  height: auto !important;
}

.pf-c-background-image::before {
  background-position: center;
}

.emailLink {
  color: #ff005b !important;
}

.link {
  color: #00a5ff !important;
}

.MuiPickersCalendarHeader-transitionContainer {
  height: 25px !important;
}

li .MuiListItemText-multiline p.MuiListItemText-secondary {
  white-space: normal;
  word-break: break-word;
}

.MuiFormGroup-root .MuiFormControlLabel-root {
  max-width: fit-content !important;
}

/* .MuiInputLabel-root{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  height: 100%;
} */
.MuiInputLabel-shrink {
  width: 120%;
  max-width: 120%;
}

.reivewContainer .MuiGrid-root.MuiGrid-item {
  display: flex;
  align-items: flex-end;
}

.reivewContainer .MuiFormLabel-filled:not(.MuiInputLabel-outlined) {
  position: relative;
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
}

.reivewContainer
  .MuiFormLabel-filled:not(.MuiInputLabel-outlined)
  + .MuiInput-root {
  margin-top: 0;
}

.addressContainer .MuiFormControlLabel-root {
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.addressContainer .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 1rem;
}

.themeSwitch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.themeSwitch .MuiSwitch-root {
  margin: 0;
}

.pf-c-login__container {
  padding-top: 24px;
}

.pf-c-brand {
  margin-bottom: 1.5rem !important;
}

.textStatic {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.8571428571428571rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  /* margin-top: 3px; */
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.MuiPopover-paper {
  overscroll-behavior: contain;
}

.selectField .MuiInputLabel-root.MuiFormLabel-root {
  max-width: calc(100% -40px);
}